// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import VueRouter from "vue-router";
import routes from "./router/index";
import store from "./vuex";
import VueRx from "vue-rx";
import iView from "iview";
import "iview/dist/styles/iview.css";
import "../my-theme/index.less";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import locale from "iview/src/locale/lang/en-US";
import util from "util";
import VueLazyload from "vue-lazyload";

import "assets/iconfont/iconfont"; // iconfont 具体图标见iconfont
import IconSvg from "components/Icon-svg"; // iconfont svg 组件
import SVGSprite from "components/SVGSprite.vue";
import Footer from "components/footer.vue";
import "./utils/dateFilter";
require("font-awesome/css/font-awesome.css");
import { globalMixin } from "./mixins";
import "./registerServiceWorker";

import "vue2-datepicker/index.css";
import { sanitizedModal } from "@/utils/MTIModal";

const Cpackage = require("../package.json");
import * as Sentry from "@sentry/vue";

import { Auth0Plugin, getInstance } from "./auth";
import { setupWebsocket } from "./websocket";

const router = new VueRouter({ routes });

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://0ec3dfb876424186bbc23e70fb6866b1@sentry.io/1368268",
    ignoreErrors: [/429/],
    release: Cpackage.version,
    autoSessionTracking: false,
    integrations: [Sentry.browserTracingIntegration({ router })],
    beforeSend(event, hint) {
      const error = hint.originalException || {};
      if (error.config?.url?.includes("/online_check")) return null;

      return event;
    },
  });
}

window.navigator &&
  window.navigator.registerProtocolHandler &&
  window.navigator.registerProtocolHandler("mailto", location.origin + "/#/sendMail?mailto=%s", "MarineTrafficInbox");

Vue.component("icon-svg", IconSvg);
Vue.component("svg-sprite", SVGSprite);
Vue.component("chartdesk-footer", Footer);

Vue.use(iView, { locale });
Vue.use(VueRouter);
Vue.use(VueRx);
const avatar = util.randomAvatar();
Vue.use(VueLazyload, {
  preLoad: 1,
  error: avatar,
  loading: avatar,
  attempt: 1,
});
Vue.use(Auth0Plugin);

Vue.mixin(globalMixin);

Vue.config.productionTip = false;

export const Toastr = {
  dispatch: (type, value) => {
    window.dispatchEvent(new CustomEvent("configToastr", { detail: { type, value } }));
  },
};

Vue.prototype.$Toastr = Toastr;
Vue.prototype.$Modal = sanitizedModal;

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      console.warn(err);
      return err;
    }
    console.log;
    // rethrow error
    return Promise.reject(err);
  });
};

VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      console.warn(err);
      return err;
    }
    console.log;
    // rethrow error
    return Promise.reject(err);
  });
};

router.beforeEach(async (to, from, next) => {
  const authService = getInstance();

  const guardAction = async () => {
    if (to.path === "/shares" || to.path === "/completeInvitation") {
      return next();
    }

    if (authService.isAuthenticated) {
      to.path != "/sendMail" && setupWebsocket();
      return next();
    }

    authService.loginWithRedirect({ appState: { targetPath: to.fullPath } });
  };

  if (!authService.loading) {
    return guardAction();
  }

  authService.$watch("loading", (isLoading) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
});

util.getDataFromSw("online", (data) => {
  store.commit("UPDATE_ONLINE_STATUS", data);
});

router.afterEach((to, from) => {
  updateDocumentTitle(to);
});

function updateDocumentTitle(to) {
  const { title } = to.meta;
  const documentTitle = typeof title === "function" ? title(to) : title;
  document.title = documentTitle || "MarineTraffic Inbox";
}

window.timeGap = JSON.parse(localStorage.getItem("timeGap"));

util.registerGoogleAnaytic();

new Vue({
  el: "#app",
  store,
  router,
  template: "<App/>",
  components: { App },
});

<template>
  <div class="folder-picker" @click.stop>
    <searchFolder ref="folderSearchEl" :type="searchType" @onSearchSelected="onSearchSelected($event, searchType)" />
    <div class="content">
      <template v-if="category === categories.MY_WORKSPACE || category === categories.ALL">
        <div class="group-title" @click="workspaceShown = !workspaceShown">MY WORKSPACE</div>
        <div v-if="workspaceShown" class="items">
          <RecursiveItem
            v-for="item in personals"
            :key="item.id"
            :item="item"
            :category="folderCategory.PERSONAL"
            :expandMethod="onExpandFolder"
            :itemToSearch="folderToSearch"
            @searchDone="searchDone"
          >
            <template slot-scope="{ item }">
              <folder
                class="folder-item"
                :ref="`folderItem${item.id}`"
                :folderItem="item"
                :category="folderCategory.PERSONAL"
                :isShowHidden="true"
                :onFolderClick="onFolderClick"
                :showContextMenu="false"
                :active="isSelected(item)"
              />
              <Icon class="checkmark" type="md-checkmark" v-if="isSelected(item)" />
            </template>
          </RecursiveItem>
        </div>
      </template>

      <template v-if="category === categories.SHARED || category === categories.ALL">
        <div class="group-title" @click="sharedShown = !sharedShown">SHARED</div>
        <div v-if="sharedShown" class="items">
          <RecursiveItem
            v-for="item in shares"
            :key="item.id"
            :item="item"
            :category="folderCategory.SHARE"
            :expandMethod="onExpandFolder"
            :itemToSearch="folderToSearch"
            @searchDone="searchDone"
          >
            <template slot-scope="{ item }">
              <folder
                class="folder-item"
                :ref="`folderItem${item.id}`"
                :folderItem="item"
                :category="folderCategory.SHARE"
                :onFolderClick="onFolderClick"
                :showContextMenu="false"
                :active="isSelected(item)"
              />
              <Icon class="checkmark" type="md-checkmark" v-if="isSelected(item)" />
            </template>
          </RecursiveItem>
        </div>
      </template>
    </div>
    <div class="footer">
      <span class="footer-item" @click="onReset">RESET</span>
      <span v-if="!hideOkButton" class="footer-item primary" @click="onOK">OK</span>
    </div>
  </div>
</template>

<script>
import searchFolder from "@/pages/mails/nav/searchFolder.vue";
import folder from "@/pages/mails/nav/folder.vue";
import RecursiveItem from "@/components/RecursiveItem.vue";

import { mapActions, mapGetters } from "vuex";
import { searchFolderMixin } from "@/mixins";
import appConstant from "@/common/constants/app.constant";

export default {
  props: {
    category: String,
    types: Array,
    value: Array,
    maxSelection: {
      type: Number,
      default: -1,
    },
    hideOkButton: Boolean,
  },
  data() {
    return {
      selectedFolders: [],
      workspaceShown: true,
      sharedShown: true,

      folderCategory: appConstant.folderCategory,
      folderTypes: appConstant.folderType,
      categories: appConstant.directory,
    };
  },
  mixins: [searchFolderMixin],
  inject: {
    closeEmailFiling: {
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["folders"]),
    searchType() {
      return this.category === appConstant.directory.ALL
        ? appConstant.folderSearchTypes.ALL
        : this.category === appConstant.directory.MY_WORKSPACE
        ? appConstant.folderSearchTypes.PERSONAL_SEARCH
        : appConstant.folderSearchTypes.SHARE_SEARCH;
    },
    personals() {
      if (this.types && this.types.length > 0)
        return this.folders.personals.filter((f) => this.types.findIndex((t) => t === f.folder_type) > -1);
      return this.folders.personals;
    },
    shares() {
      if (this.types && this.types.length > 0)
        return this.folders.shares.filter((f) => this.types.findIndex((t) => t === f.folder_type) > -1);
      return this.folders.shares;
    },
  },
  methods: {
    ...mapActions(["loadChildFolders"]),
    onSearchSelected(folder) {
      this.$refs.folderSearchEl && this.$refs.folderSearchEl.clearTextSearch();
      this.folderToSearch = { ...folder, action: this.searchFolderActions.HIGHLIGHT };
      this.removeHighlightFolder();
    },
    removeHighlightFolder() {
      const highlightEls = document.querySelectorAll(".folder-picker .folder-name .highlight");
      highlightEls &&
        highlightEls.forEach((el) => {
          el && el.classList && el.classList.remove("highlight");
        });
    },
    async onExpandFolder({ category, id, expanding, no_cache, noNeedLoadChildren }) {
      try {
        if (noNeedLoadChildren || !expanding) return;
        await this.loadChildFolders({ parentId: id, folder_types: this.types, category, no_cache });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    onFolderClick(folder) {
      this.removeHighlightFolder();

      if (!this.isSelected(folder) && this.maxSelection > -1 && this.selectedFolders.length >= this.maxSelection) {
        this.$Message.error("You have reached the maximum number of folders");
        return;
      }

      if (!this.isSelected(folder)) {
        this.selectedFolders.push(folder);
        this.$emit("change", this.selectedFolders);
        return;
      }

      const index = this.selectedFolders.findIndex((f) => f.id == folder.id);
      index > -1 && this.selectedFolders.splice(index, 1);
      this.$emit("change", this.selectedFolders);
    },
    onOK() {
      this.$emit("done", this.selectedFolders);
      this.closeEmailFiling && this.closeEmailFiling();
    },
    onReset() {
      this.selectedFolders = [];
      this.$emit("change", this.selectedFolders);
    },
    isSelected(folder) {
      return this.selectedFolders.findIndex((f) => f.id == folder.id) !== -1;
    },
    initSelectedFolders() {
      this.selectedFolders = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: "initSelectedFolders",
    },
  },
  components: {
    searchFolder,
    folder,
    RecursiveItem,
  },
};
</script>

<style lang="scss" scoped>
.folder-picker {
  border: 1px solid var(--border-color);
  background: var(--component-color);
  color: var(--on-component-color);
  font-size: 13px;
  .header {
    padding: 1px;
    border-bottom: 1px solid var(--border-color);
  }
  .content {
    height: 250px;
    overflow: auto;
    overflow: overlay;
    font-weight: normal;
    padding-bottom: 16px;
    .group-title {
      padding: 8px;
      font-weight: bold;
      cursor: pointer;
    }
    .items {
      padding-left: 8px;
    }
    .folder-item {
      flex: 1;
    }
    .checkmark {
      font-size: 16px;
      font-weight: bold;
      color: var(--primary-color);
      padding: 0 16px;
    }
  }
  .footer {
    display: flex;
    color: var(--primary-color);
    font-weight: bold;
    text-align: center;
    border-top: 1px solid var(--border-color);
    .footer-item {
      flex: 1;
      padding: 10px;
      cursor: pointer;
      &.primary {
        background-color: var(--blue-main);
        color: var(--on-primary-color);
      }
      &:last-child {
        border-left: 1px solid var(--border-color);
      }
      &[disabled] {
        cursor: not-allowed;
      }
    }
  }
}
</style>

<style lang="scss">
.folder-picker {
  .folder-search-input-container {
    margin: 0 !important;
    .ivu-select-dropdown,
    ul {
      width: auto !important;
      .name {
        white-space: nowrap;
        text-overflow: unset;
        overflow-x: unset;
      }
    }
    .ivu-select-selection {
      height: 40px;
      border: none;
      border-bottom: 1px solid var(--border-color);
      border-radius: 0;
      box-shadow: none;
    }
    .ivu-select-dropdown {
      margin: 0;
      border-radius: 0;
    }
    .ivu-select-input {
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>

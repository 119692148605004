import {
  UPDATE_MAIL_LIST,
  UPDATE_MAIL_LIST_ITEMS,
  DELETE_MAIL_LIST_ITEMS,
  SET_OPEN_TABS,
  ADD_OPEN_TAB,
  REMOVE_OPEN_TAB,
  RESET_OPEN_TABS,
  SET_ACTIVE_TAB,
  SET_EXPANDED_PREVIEW_MODE,
  UPDATE_INAPP_ATTACHMENT_PREVIEW,
  ADD_TAB_ATTACHMENT,
  RESET_TAB_ATTACHMENT,
  UPDATE_CURRENT_TAB_ATTACHMENT,
  SET_LIVE_FEEDS,
  SET_MAILS_SETTINGS,
  UPDATE_MAIL_PATH,
  SET_SETTING_CHANGE,
  SET_EMAIL_OUT_OF_SYNC,
} from "../../mutation-type";
import api from "api";
import LocalStorageConstant from "@/common/constants/local-storage.constant";
import appConstant from "@/common/constants/app.constant";
import util from "util";
import { LRUCache } from "@/utils";
import dayjs from "dayjs";

import userStore from "../user";

export const emailNotificationCache = new LRUCache(20);

const state = {
  mailList: [],
  mailNum: 0,
  pageSize: 50,
  bookmark: undefined,
  autoBookmark: undefined,
  openingTabs: JSON.parse(localStorage.getItem(LocalStorageConstant.OPEN_MAIL_TABS)) || [],
  activeTab: null,
  mailPath: "",
  previewExpanded: false,
  inappAttachmentPreview: {
    isPreviewing: false,
    maximized: false,
    mailDetailPreview: null,
    attachment: null,
    key: "",
  },
  tabAttachments: [],
  currentTabAttachmentId: null,
  liveFeeds: [],
  emailOutOfSync: false,
};

const getters = {
  mailPath: (state) => state.mailPath,
  mailList: (state) => state.mailList,
  mailNum: (state) => state.mailNum,
  pageSize: (state) => state.pageSize,
  bookmark: (state) => state.bookmark,
  autoBookmark: (state) => state.autoBookmark,
  openingTabs: (state) => state.openingTabs,
  activeTab: (state) => state.activeTab,
  previewExpanded: (state) => state.previewExpanded,
  inappAttachmentPreview: (state) => state.inappAttachmentPreview,
  tabAttachments: (state) => state.tabAttachments,
  currentTabAttachmentId: (state) => state.currentTabAttachmentId,
  liveFeeds: (state) => state.liveFeeds,
  emailOutOfSync: (state) => state.emailOutOfSync,
};
const mutations = {
  [UPDATE_MAIL_LIST](state, param) {
    state.mailList = param.emails.map((email) => {
      if (!email.star) email.star = false;
      return email;
    });

    if (!param.key) return;
    if (!util.routeBasedEmails[param.key]) return;

    const emailMap = new Map();
    for (let i = 0; i < param.emails.length; i++) {
      emailMap.set(param.emails[i].id, param.emails[i]);
    }
    util.routeBasedEmails[param.key].emailMap = emailMap;
  },

  [UPDATE_MAIL_LIST_ITEMS](_, { ids, changedProps }) {
    util.updateAllCachedEmails({ ids, changedProps });
  },

  [DELETE_MAIL_LIST_ITEMS](state, { ids, changedProps, currentCacheKey, includeKeys }) {
    util.updateAllCachedEmails({ ids, changedProps, remove: true, includeKeys });
    const current = util.routeBasedEmails[currentCacheKey];
    if (!current || !current.emailMap) return;

    const currentMails = [...current.emailMap.values()];
    if (!currentMails) return;
    state.mailList = currentMails;
  },

  setStarStatus(state, param) {
    const { selected, mailIds } = param;
    mailIds.forEach((mailId) => {
      let mail = state.mailList.find((m) => m.id == mailId);
      if (mail) mail.star = selected;
    });
  },

  removeMails(state, mailIds) {
    mailIds.forEach((id) => {
      const i = state.mailList.findIndex((m) => m.id == id);
      i != -1 && state.mailList.splice(i, 1);
      state.mailNum = state.mailNum - 1;
    });
  },

  setBookmark(state, bookmark = {}) {
    if (bookmark.bookmark_type === "default") {
      state.bookmark = bookmark;
      return;
    }

    if (bookmark.bookmark_type === "invisible") {
      let localSavedBookmark = localStorage.getItem(LocalStorageConstant.AUTO_BOOKMARK);

      if (!localSavedBookmark) {
        state.autoBookmark = { ...bookmark, updated_at: bookmark.updated_at || new Date().toISOString() };
        localStorage.setItem(LocalStorageConstant.AUTO_BOOKMARK, JSON.stringify(state.autoBookmark));
        return;
      }

      localSavedBookmark = JSON.parse(localSavedBookmark);

      if (
        bookmark?.email_id !== localSavedBookmark?.email_id &&
        new Date(localSavedBookmark?.updated_at) > new Date(bookmark?.updated_at)
      )
        return;

      state.autoBookmark = { ...bookmark, updated_at: bookmark?.updated_at || new Date().toISOString() };
      localStorage.setItem(LocalStorageConstant.AUTO_BOOKMARK, JSON.stringify(state.autoBookmark));
    }
  },

  [SET_OPEN_TABS]: (state, tabs) => {
    state.openingTabs = tabs;
    localStorage.setItem(LocalStorageConstant.OPEN_MAIL_TABS, JSON.stringify(tabs));
  },
  [ADD_OPEN_TAB]: (state, { tab }) => {
    const index = state.openingTabs.findIndex((t) => t.name == tab.name);
    if (index == -1) {
      state.openingTabs.push(tab);
      localStorage.setItem(LocalStorageConstant.OPEN_MAIL_TABS, JSON.stringify(state.openingTabs));
    }
  },
  [REMOVE_OPEN_TAB]: (state, { tabName }) => {
    const index = state.openingTabs.findIndex((t) => t.name == tabName);
    if (index != -1) {
      state.openingTabs.splice(index, 1);
      localStorage.setItem(LocalStorageConstant.OPEN_MAIL_TABS, JSON.stringify(state.openingTabs));
    }
    state.activeTab && tabName == state.activeTab.name && (state.activeTab = null);
  },
  [RESET_OPEN_TABS]: (state) => {
    state.activeTab = null;
    state.openingTabs = [];
    localStorage.setItem(LocalStorageConstant.OPEN_MAIL_TABS, JSON.stringify(state.openingTabs));
  },
  [SET_ACTIVE_TAB]: (state, tab) => {
    state.activeTab = tab;
  },
  [SET_EXPANDED_PREVIEW_MODE]: (state, status) => {
    state.previewExpanded = status;
  },
  [UPDATE_INAPP_ATTACHMENT_PREVIEW]: (state, data) => {
    state.inappAttachmentPreview = data.attachment
      ? { ...state.inappAttachmentPreview, ...data, key: Date.now().toString() }
      : { ...state.inappAttachmentPreview, ...data };
  },
  [ADD_TAB_ATTACHMENT]: (state, attachment) => {
    state.currentTabAttachmentId = attachment.id;
    if (state.tabAttachments.some((a) => a.id === attachment.id)) return;

    state.tabAttachments = [...state.tabAttachments, attachment];
  },
  [RESET_TAB_ATTACHMENT]: (state) => {
    state.tabAttachments = [];
  },
  [UPDATE_CURRENT_TAB_ATTACHMENT]: (state, id) => {
    state.currentTabAttachmentId = id;
  },
  [SET_LIVE_FEEDS]: (state, data) => {
    data = data || [];

    if (!Array.isArray(data)) return;

    data = JSON.parse(JSON.stringify(data));

    state.liveFeeds = (data || []).map((feed) => {
      feed.utils = {};

      feed.utils.isMailboxMatched = (lineIds) => {
        return feed.mailbox.some((mb) => lineIds.includes(mb));
      };

      feed.utils.isTypeMatched = (box = "") => {
        let typeMatched = false;

        if (feed.type === "all") typeMatched = true;
        if (feed.type === "incoming") typeMatched = box.toLowerCase() === "inbox";
        if (feed.type === "outgoing") typeMatched = box.toLowerCase() === "sent";

        return typeMatched;
      };

      feed.utils.isSubjectIncludeMatched = (subject) => {
        let subjectMatched = false;

        const subjectFilter = feed.filters.find((f) => f.id === "subject" && f.type === "include");
        if (!subjectFilter || !subjectFilter.value) return true;

        const keywords = subjectFilter.value.split(" ");
        keywords.forEach((keyword) => {
          const regex = new RegExp(keyword, "gi");
          if (regex.test(subject)) subjectMatched = true;
        });

        return subjectMatched === true;
      };

      feed.utils.isSubjectExcludeMatched = (subject) => {
        let subjectMatched = false;

        const subjectFilter = feed.filters.find((f) => f.id === "subject" && f.type === "exclude");
        if (!subjectFilter || !subjectFilter.value) return true;

        const keywords = subjectFilter.value.split(" ");
        keywords.forEach((keyword) => {
          const regex = new RegExp(keyword, "gi");
          if (regex.test(subject)) subjectMatched = true;
        });

        return subjectMatched === false;
      };

      feed.utils.isDateMatched = (date) => {
        const dateFilter = feed.filters.find((f) => f.id === "date");
        if (!dateFilter || !dateFilter.value) return true;
        const parsedDate = +Number(dateFilter.value) || 1;
        const dateEnd = dayjs().subtract(parsedDate - 1, "day");
        return dayjs(date).isAfter(dateEnd) || dayjs(date).isSame(dayjs(), "day");
      };

      feed.utils.isFromContainMatched = (from) => {
        let fromMatched = false;

        const fromFilter = feed.filters.find((f) => f.id === "from" && f.type === "include");
        if (!fromFilter || !fromFilter.value) return true;

        const keywords = fromFilter.value.split(" ");

        keywords.forEach((keyword) => {
          const regex = new RegExp(keyword, "gi");
          if (regex.test(from)) fromMatched = true;
        });

        return fromMatched === true;
      };

      feed.utils.isFromExcludeMatched = (from) => {
        let fromMatched = false;

        const fromFilter = feed.filters.find((f) => f.id === "from" && f.type === "exclude");
        if (!fromFilter || !fromFilter.value) return true;

        const keywords = fromFilter.value.split(" ");

        keywords.forEach((keyword) => {
          const regex = new RegExp(keyword, "gi");
          if (regex.test(from)) fromMatched = true;
        });

        return fromMatched === false;
      };

      feed.utils.updateQueryParams = (params = {}, isBookmark = false) => {
        const inactiveLineIds = (userStore.getters.setting_mails.lines_unsubscription || "").split(",");

        if (!params.q) params.q = {};
        if (!params.q.rules) params.q.rules = [];
        if (typeof params.q.rules === "string") {
          try {
            params.q.rules = JSON.parse(params.q.rules);
          } catch (error) {
            console.error("Failed to parse params.q.rules:", error);
          }
        }

        params.q.line_ids = feed.mailbox.filter((b) => !inactiveLineIds.includes(b.toString())).join(",");
        params.q.match_type = "every";
        params.q.mail_scope = feed.type === "all" ? "both" : feed.type;

        const subjectFilterInclude = feed.filters.find((f) => f.id === "subject" && f.type === "include");
        if (subjectFilterInclude && subjectFilterInclude.value) {
          params.q.rules.push({
            field: "subject",
            content_type: "single",
            strategy: "contains",
            content: subjectFilterInclude.value,
            case_sensitive: false,
          });
        }

        const subjectFilterExclude = feed.filters.find((f) => f.id === "subject" && f.type === "exclude");
        if (subjectFilterExclude && subjectFilterExclude.value) {
          params.q.rules.push({
            field: "subject",
            content_type: "single",
            strategy: "not_contains",
            content: subjectFilterExclude.value,
            case_sensitive: false,
          });
        }

        const date = feed.filters.find((f) => f.id === "date");
        if (date && date.value) {
          const start = dayjs()
            .startOf("day")
            .subtract(+date.value - 1, "day")
            .toDate();
          const end = dayjs().add(1, "year").toDate();
          params.q.rules.push({
            field: "received_at",
            content_type: "range",
            strategy: "equal",
            content: start + "," + end,
            case_sensitive: false,
          });
        }

        const onlyFromFilter = feed.filters.find((f) => f.id === "from" && f.type === "include");
        if (onlyFromFilter && onlyFromFilter.value) {
          params.q.rules.push({
            field: "from",
            content_type: "single",
            strategy: "contains",
            content: onlyFromFilter.value,
            case_sensitive: false,
          });
        }

        const exceptFromFilter = feed.filters.find((f) => f.id === "from" && f.type === "exclude");
        if (exceptFromFilter && exceptFromFilter.value) {
          params.q.rules.push({
            field: "from",
            content_type: "single",
            strategy: "not_contains",
            content: exceptFromFilter.value,
            case_sensitive: false,
          });
        }

        params.q.rules = JSON.stringify(params.q.rules);

        if (!isBookmark) {
          delete params.bookmark;
        }
      };

      return feed;
    });
  },
  [UPDATE_MAIL_PATH]: (state, params) => {
    state.mailPath = params;
  },
  [SET_EMAIL_OUT_OF_SYNC]: (state, val) => {
    state.emailOutOfSync = val;
  },
};

const actions = {
  starMail({ commit, dispatch }, param) {
    const { selected, mailIds } = param;
    commit("setStarStatus", { selected, mailIds });
    !selected &&
      state.bookmark &&
      state.bookmark.params.is_star == "true" &&
      mailIds.findIndex((id) => id == state.bookmark.email_id) > -1 &&
      dispatch("removeBookmark", state.bookmark);
    util.sendMessageToSw({
      message: "removeEmailDetail",
      email_ids: mailIds || [],
    });
    return selected ? api.star_mail(mailIds) : api.unstar_mail(mailIds);
  },
  removeMails({ commit, dispatch }, mailIds) {
    commit("removeMails", mailIds);
    [state.bookmark, state.autoBookmark].forEach((bookmark) => {
      bookmark && mailIds.findIndex((id) => id == bookmark.email_id) > -1 && dispatch("removeBookmark", bookmark);
    });
  },
  archive({ dispatch }, ids) {
    [state.bookmark, state.autoBookmark].forEach((bookmark) => {
      bookmark && ids.findIndex((id) => id == bookmark.email_id) > -1 && dispatch("removeBookmark", bookmark);
    });
    return api
      .archive_email({
        email_ids: ids,
        archive: true,
      })
      .catch((error) => {
        console.log(error);
      });
  },
  unarchive({ dispatch }, ids) {
    [state.bookmark, state.autoBookmark].forEach((bookmark) => {
      bookmark &&
        bookmark.params.archived == "true" &&
        ids.findIndex((id) => id == bookmark.email_id) > -1 &&
        dispatch("removeBookmark", bookmark);
    });
    return api
      .archive_email({
        email_ids: ids,
        archive: false,
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async addBookmark({ commit }, bookmark) {
    try {
      const res = await api.add_bookmark(bookmark);
      const addLocalAutoBookmarkSuccess = bookmark?.bookmark_type === appConstant.bookmarkType.INVISIBLE;
      commit("setBookmark", { ...bookmark, ...res, addLocalAutoBookmarkSuccess });
    } catch (error) {
      if (bookmark.bookmark_type === appConstant.bookmarkType.INVISIBLE) {
        localStorage.removeItem(LocalStorageConstant.AUTO_BOOKMARK);
      }
    }
  },
  removeBookmark({ commit, state }, bookmark) {
    if (!bookmark) return;

    if (bookmark.bookmark_type === appConstant.bookmarkType.INVISIBLE) {
      state.autoBookmark = undefined;
      localStorage.removeItem(LocalStorageConstant.AUTO_BOOKMARK);
    }

    if (bookmark.bookmark_type === appConstant.bookmarkType.DEFAULT) {
      state.bookmark = undefined;
    }

    if (!bookmark.id) return;

    return api.remove_bookmark(bookmark.id);
  },
  async getBookmark({ commit }) {
    let localSavedBookmark = localStorage.getItem(LocalStorageConstant.AUTO_BOOKMARK);
    if (localSavedBookmark) commit("setBookmark", JSON.parse(localSavedBookmark));

    const res = await api.get_bookmark();
    if (!res || !Array.isArray(res.bookmarks)) return;
    res.bookmarks.forEach((b) => commit("setBookmark", b));
    return res.bookmarks;
  },
  markAsSpam({ commit, dispatch }, ids) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.mark_as_spam(ids);
        [state.bookmark, state.autoBookmark].forEach((bookmark) => {
          bookmark &&
            bookmark.id &&
            ids.findIndex((id) => id == bookmark.email_id) > -1 &&
            dispatch("removeBookmark", bookmark);
        });
        util.sendMessageToSw({
          message: "removeEmailDetail",
          email_ids: ids || [],
        });
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
  unSpamEmails({ dispatch }, ids) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.unspam_emails({ ids: ids });
        [state.bookmark, state.autoBookmark].forEach((bookmark) => {
          bookmark &&
            bookmark.id &&
            bookmark.params.box &&
            bookmark.params.box.toLowerCase() == "spam" &&
            ids.findIndex((id) => id == bookmark.email_id) > -1 &&
            dispatch("removeBookmark", bookmark);
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  },
  async getOpenTabs({ commit }, triggerActiveTab) {
    const res = await api.get_openedTabs();
    if (!res) return;

    const { tabs } = res;

    commit(SET_OPEN_TABS, tabs);

    if (!triggerActiveTab) return;

    const activeTab = tabs.find((tab) => tab.active === true);
    if (!activeTab) return;
    commit(SET_ACTIVE_TAB, activeTab);
  },
  async lockDraft({ commit }, emailId) {
    return api.lock_draft(emailId, "active");
  },
  async unlockDraft({ commit }, emailId) {
    return api.lock_draft(emailId, "deactive");
  },
  async deleteDraft({ commit, state, dispatch }, draftId) {
    [state.bookmark, state.autoBookmark].forEach((bookmark) => {
      bookmark &&
        bookmark.params.box == "Draft" &&
        draftId == bookmark.email_id &&
        dispatch("removeBookmark", bookmark);
    });

    return api.delete_draft_mail(draftId, {
      email_id: draftId,
    });
  },
  async updateLiveFeeds({ commit, state }, fn) {
    if (typeof fn !== "function") return;

    const newLiveFeeds = fn(state.liveFeeds) || state.liveFeeds;

    const feeds = newLiveFeeds.map((f) => {
      delete f["utils"];
      return f;
    });

    const res = await api.set_user_settings({ keys: ["live_feed"], values: [JSON.stringify(feeds)] });

    if (!res) return;
    commit(SET_SETTING_CHANGE, true);
    commit(SET_MAILS_SETTINGS, res.settings);
    commit(SET_LIVE_FEEDS, newLiveFeeds);
  },
  async syncAutoBookmark({ state, dispatch }) {
    const reRun = () => {
      setTimeout(() => {
        actions.syncAutoBookmark({ state, dispatch });
      }, 30 * 1000);
    };

    let localSavedBookmark = localStorage.getItem(LocalStorageConstant.AUTO_BOOKMARK);
    if (!localSavedBookmark) return reRun();

    localSavedBookmark = JSON.parse(localSavedBookmark);
    if (localSavedBookmark?.addLocalAutoBookmarkSuccess || localSavedBookmark.id) return reRun();

    await dispatch("addBookmark", localSavedBookmark);
    reRun();
  },
  saveLocalAutoBookmark() {
    let bookmark = localStorage.getItem(LocalStorageConstant.AUTO_BOOKMARK);
    if (!bookmark) return;
    bookmark = JSON.parse(bookmark);
    if (bookmark?.id || bookmark?.addLocalAutoBookmarkSuccess) return;
    return api.add_bookmark(bookmark);
  },
  async removeTab({ commit, state, dispatch }, payload) {
    const tab = state.openingTabs.find((t) => {
      if (t.name_type === payload.type && new RegExp(payload.prefix).test(t.name)) {
        const tab_id = t.name.split(payload.prefix)[1] || "";
        return String(tab_id) === String(payload.id);
      }
    });
    if (!tab || !tab.closable) return;
    api.remove_tab(tab.id);
    commit(REMOVE_OPEN_TAB, { tabName: tab.name });

    [state.bookmark, state.autoBookmark].forEach((bookmark) => {
      if (!bookmark) return;
      if ((bookmark.params.current_path || "").includes(tab.name)) {
        dispatch("removeBookmark", bookmark);
      }
    });
  },
  async getEmailBody({ dispatch }, { expires_at, token }) {
    try {
      const res = await api.get_bodies({ token });
      util.updateAllCachedEmails({
        ids: [...res.map((item) => item.email_id)],
        changedProps: (item) => {
          let body = res?.find((body) => body.email_id === item.id);
          const attachments = body.attachments.map((att) => {
            const current_attachments = item.attachments.find((a) => a.id === att.id);
            return {
              ...current_attachments,
              original_file_url: att.original_file_url,
            };
          });
          return { ...body, attachments: attachments };
        },
      });
    } catch (err) {
      console.log(err);
    }
  },
  async deleteRecentComposeEmail({ dispatch }, params) {
    try {
      let tmp = localStorage.getItem(`recent-email`);
      tmp = tmp ? JSON.parse(tmp) : [];
      tmp = tmp.filter((item) => item !== params?.address);
      localStorage.setItem(`recent-email`, JSON.stringify(tmp));
      await api.delete_recent_search_email(params);
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

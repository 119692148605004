import Vue from "vue";
import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import { bus } from "@/helpers/eventBus.helper";
import { auth0_clientId, auth0_domain } from '@/config/index';

let instance;
let tokenPromise = null;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = (options) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      try {
        // Create a new instance of the SDK client using members of the given options object
        this.auth0Client = new Auth0Client(options);

        // If the user is returning to the app after authentication
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          const token = await this.handleRedirectCallback();
          token && bus.$emit("handleAuth0Done", { token });
          return;
        }
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        this.loading = false;
      } catch (e) {
        this.error = e;
        console.log("Auth0 Error: ", e);
      }
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          this.error = e;
          console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          const token = await this.getTokenSilently();
          this.isAuthenticated = true;
          this.error = null;
          return token;
        } catch (e) {
          this.error = e;
          console.log("handleRedirectCallback ex: ", e);
        } finally {
          // this.loading = false;
        }
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getTokenSilently(o) {
        // Allow only one request to getTokenSilently
        // All the caller will wait the promise and access the token from the resolved value
        // In case concurrent requests create auth issues, we can use more robust method for concurrency control like async-mutex
        if (!tokenPromise) {
          tokenPromise = this.auth0Client
            .getTokenSilently()
            .then((token) => {
              tokenPromise = null; // Reset after completion
              return token;
            })
            .catch((ex) => {
              tokenPromise = null; // Reset on error
              return undefined; // Return undefined instead of throwing the error
            });
        }
        return tokenPromise;
      },
      /** Gets the access token using a popup window */
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      },
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue) {
    Vue.prototype.$auth = useAuth0({
      domain: auth0_domain,
      clientId: auth0_clientId,
      cacheLocation: "localstorage",
      leeway: 120,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      authorizationParams: {
        redirect_uri: `${window.location.origin}/#/oauth/callback`,
        audience: "https://inbox.marinetraffic.com",
        scope: "openid profile email mti:access",
      },
    });
  },
};

<template>
  <div id="app">
    <div class="loading" v-show="authLoading">
      <Spin size="large" fix></Spin>
    </div>
    <div class="alert-message-box">
      <Toastr />
      <Alert
        v-if="announcement.content && !announcement.closed"
        type="info"
        :content="sanitizeHTML(announcement.content)"
        @close="handleCloseMaintenanceNotice"
      />
      <Alert
        v-if="offlineError"
        type="error"
        content="Error: Your browser is not able to connect to MarineTraffic Inbox. Are you offline?"
        @close="handleCloseOfflineErrorMessage"
      />
      <Modal v-model="synchronizingData" footer-hide :closable="false">
        <div slot="header" style="text-align: center; font-weight: bold">Logging out</div>
        <div style="display: flex; justify-content: center; align-items: center; gap: 8px">
          <Icon type="md-sync" size="20" />
          <span>Please wait while we sync your data</span>
        </div>
      </Modal>
    </div>
    <keep-alive :include="cachedComponents">
      <router-view @route-data-loaded="changeTitle" />
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import util from "util";
import api from "api";
import { hotkeyHandler } from "@/mixins";
import Alert from "./components/Alert.vue";
import md5 from "md5";
import Toastr from "./components/Toastr.vue";
import { bus } from "@/helpers/eventBus.helper";
import LocalStorageConstant from "./common/constants/local-storage.constant";

let announcementInterval;
export default {
  name: "app",
  data() {
    return {
      savedPinMenu: false,
      showNotiBanner: true,
      announcement: {
        content: "",
        md5: "",
        closed: true,
      },
      offlineError: false,
      cachedComponents: [],
      authLoading: false,
    };
  },
  computed: {
    ...mapState(["online"]),
    ...mapGetters(["userInfo", "setting_mails", "setting_company", "inappMailState", "synchronizingData"]),
  },
  mixins: [hotkeyHandler],
  components: {
    Alert,
    Toastr,
  },
  methods: {
    ...mapActions(["composeMail", "getTags", "unlockDraft", "deleteDraft", "getHelpCenterVersion"]),
    changeTitle(title) {
      if (title) {
        document.title = `${title}`;
      }
    },
    configMessage() {
      this.$Message.config({
        duration: 3,
      });
    },
    handleHotkey(e) {
      if (e.key == "n" || (e.key == "n" && (e.ctrlKey || e.metaKey))) {
        e.preventDefault();
        this.composeMail({ inNewWindow: this.composeInNewWindow });
      }
    },
    handleSocket() {
      if (!this.$ActionCable || !this.$ActionCable.subscriptions) return;
      let socket = this.$ActionCable.subscriptions.subscriptions[0];
      let self = this;
      socket.received.companyTag = function (tags) {
        self.$store.commit("SET_CONFIGURED_TAGS", tags);
      };
    },
    setupAnnouncementInterval() {
      if (/previewEml|sendmail|mail\/\d+/i.test(window.location.href)) return;

      this.fetchAnnouncement();
      announcementInterval = setInterval((_) => {
        this.fetchAnnouncement();
      }, 1000 * 60 * 30);
      util.functionBus.removeAnnouncementInterval = () => clearInterval(announcementInterval);
    },
    fetchAnnouncement() {
      api
        .fetch_announcement()
        .then((data) => {
          const content = (data && data.data) || "";
          let savedAnnouncement = localStorage.getItem("announcement");

          if (!savedAnnouncement) {
            savedAnnouncement = {};
          } else {
            savedAnnouncement = JSON.parse(savedAnnouncement);
          }

          const newAnnouncement = {
            content,
            md5: md5(content),
            closed: false,
          };

          if (savedAnnouncement.md5 === newAnnouncement.md5 && savedAnnouncement.closed) return;

          this.announcement = newAnnouncement;
          savedAnnouncement.md5 !== newAnnouncement.md5 &&
            localStorage.setItem("announcement", JSON.stringify(newAnnouncement));
        })
        .catch((_) => {
          this.announcement = {
            content: "",
            md5: "",
            closed: true,
          };
        });
    },
    onVisibilityChanged() {
      if (document.hidden) clearInterval(announcementInterval);
      else this.setupAnnouncementInterval();
    },
    handleCloseMaintenanceNotice() {
      this.announcement.closed = true;
      localStorage.setItem("announcement", JSON.stringify(this.announcement));
      clearInterval(announcementInterval);
    },
    handleCloseOfflineErrorMessage() {
      this.offlineError = false;
    },
    handlePopupClose(event) {
      if (!event || !event.detail) return;
      switch (event.detail.action) {
        case "unlock_draft":
          event.detail.id && this.unlockDraft(event.detail.id);
          break;
        case "delete_draft":
          event.detail.id && this.deleteDraft(event.detail.id);
          break;
      }
    },
    setEventBus() {
      bus.$on("addComponentCache", this.handleAddComponentCache);
      bus.$on("removeComponentCache", this.handleRemoveComponentCache);
      bus.$on("resetCachedComponent", this.resetCachedComponent);
      bus.$on("handleAuth0Done", this.handleAuth0Done);
      bus.$on("logout", this.handleLogout);
    },
    clearEventBus() {
      bus.$off("addComponentCache", this.handleAddComponentCache);
      bus.$off("removeComponentCache", this.handleRemoveComponentCache);
      bus.$off("resetCachedComponent", this.resetCachedComponent);
      bus.$off("handleAuth0Done", this.handleAuth0Done);
      bus.$off("logout", this.handleLogout);
    },
    handleAddComponentCache(componentName) {
      if (!this.cachedComponents.includes(componentName)) {
        this.cachedComponents.push(componentName);
      }
    },
    handleRemoveComponentCache(componentName) {
      this.cachedComponents = this.cachedComponents.filter((name) => name !== componentName);
    },
    resetCachedComponent() {
      this.cachedComponents = [];
    },
    async handleAuth0Done({ token }) {
      try {
        await this.$store.dispatch("handleAuth0Done", { token });
        this.$auth && (this.$auth.loading = false);
        window.history.replaceState({}, document.title, "/");
        const url = window._newVersion ? "/mails?_newVersion=true" : "/mails";
        this.$router.push(url);
      } catch (ex) {
        this.$auth && (this.$auth.loading = false);
        this.handleLogout();
        console.log("handleAuth0Done error: ", ex);
      }
    },
  },
  async created() {
    this.cachedComponents = ["mail", "navMail"];
    this.setupAnnouncementInterval();

    const token = await this.$auth.getTokenSilently();
    const user = util.storeInLocalStorage.get(LocalStorageConstant.USER);
    if (
      token &&
      user &&
      !["previewEmlWindow", "sendMailWindow"].includes(this.$route.name) &&
      this.$auth &&
      !this.$auth.loading
    ) {
      await api.getBaseUrl(token);
      this.$store.dispatch("handleInitialSetup", {
        userId: util.getUserId(),
        themeColour: this.setting_mails.theme_colour,
      });
      this.$store.dispatch("loadConfigurationData");
    }
  },
  mounted() {
    this.handleSocket();
    this.configMessage();
    window.addEventListener("offline", () => {
      util.sendMessageToSw({
        message: "checkOnlineStatus",
      });
    });
    window.addEventListener("popupbeforeunload", this.handlePopupClose);
    this.setEventBus();
  },
  beforeDestroy() {
    clearInterval(announcementInterval);
    this.clearEventBus();
  },
  watch: {
    online(val) {
      this.offlineError = !val;
    },
    "$auth.loading": {
      immediate: true,
      handler(val) {
        this.authLoading = val;
      },
    },
  },
};
</script>

<style lang="scss">
@import "../public/static/style/public.scss";
@import "./common/scss/common";
@import "./common/iconfont/iconfont.css";
@import "./common/iconfont_v2/style.css";
@import "./common/fabric_icon/fabric-icons.css";

.notice {
  position: relative;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  background-color: var(--background-color);
  color: var(--primary-color);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-right: 5px;
    font-size: 16px;
  }
  a {
    padding: 0 5px;
    text-decoration: underline;
  }
}
.maintenance-notice {
  background-color: red;
  color: #fff;
  position: relative;
  padding: 0 20px;
  height: 20px;
  line-height: 20px;

  i {
    position: absolute;
    right: 10px;
    padding: 3px;
    border-radius: 50%;
    font-size: 10px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
}
.hide-overflow {
  overflow: hidden;
}
.alert-message-box {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
